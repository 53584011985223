import Tools from "../../utils/Tools";
let SMPNum = require("../../../src/SMPNum");
let mathjs = require('mathjs');

class SupportKpiUtils {

    constructor(supportSkillList, gameDataService, supportConstance){
        this.skillList = supportSkillList;
        this.gameDataService = gameDataService;
        this.supportDatas = gameDataService.supportGamePlay.supportData;
        this.supportConstance = supportConstance;
    }

    computeSkillBonus(skillType,supportList) {

        //let team = this.getGamePlayUnlockedTeam(teamSize);
        let skillValue = 0.0;
        supportList.forEach(supports =>{
            supports.forEach(support =>{
                skillValue = skillValue + this.getSupportCumulatedPercent(support.m_iID,skillType, supportList);
            });
        });

        //in game play, this support skill value not divide with 100
        //but in this editor, we have divide, so we need to multiple with 100
        skillValue = skillValue * 100;

        skillValue = parseFloat(mathjs.format(skillValue,{precision:14}));

        return skillValue;
    }

    getSupportCumulatedPercent(supportId,skillType,supportList){
        //la est-ce que je fais les mult
        //la je
        //en fait sur le cumul y a dexu choses, on fait le cumul sur les skills.
        //mais sans les perdre

        let res = 0;
        let lsSkill = this.getSkillGamePlayMatchingType(supportId, skillType, supportList);//this.getSkillMatchingType(this.skillList,supportId,skillType);
        //console.log("Unlock count: "+listSkillsMatchingTYpe.length);
        lsSkill.skills.forEach(skill => {
            res = res + this.computeEvolve(lsSkill.possibleSkillLevel, skill);
        });
        res = mathjs.format(res,{precision:14});
        return parseFloat(res);
    }

    getSkillGamePlayMatchingType(supportId, skillType, supportList) {
        //let supportData = this.supportDatas.find(s => s.m_iID === supportId);
        let res = [];
        let possibleSkillLevel = 0;
        supportList.forEach(supports => {
            supports.forEach(support => {
                if(support.m_iID === supportId){
                    possibleSkillLevel = support.possibleSkillLevel;
                    support.m_SupportsAbilityList.forEach(skill => {
                        let skillTypeName = this.supportConstance.bySkillTypeId(skill.m_skillType);
                        if((skillTypeName) === skillType){
                            res.push(skill);
                        }
                    })
                }
            });
        });

        return {
            possibleSkillLevel: possibleSkillLevel,
            skills: res,
        };
    }

    computeEvolve  (possibleSkillLevel,  {m_skillPercent, m_iCurrentLevel}) {
        if (typeof m_skillPercent === 'string') {
            m_skillPercent = parseFloat(m_skillPercent);
        }

        let isEvolve = possibleSkillLevel > 1000;

        if (isEvolve) {
            let evolveCounter =  Math.floor(possibleSkillLevel * 1.0 / 1000);

            let skillLevel = m_iCurrentLevel + (evolveCounter * 1000);
            let isUnlockInCurrentEvolve = possibleSkillLevel >= skillLevel;

            let skillPercent = 0;

            if(isUnlockInCurrentEvolve){
                skillPercent = this.FloorDecimal(m_skillPercent + (evolveCounter * 0.1 * m_skillPercent));
                //console.log(' evolve: '+evolveCounter+' m_skillPercent '+m_skillPercent+" total "+skillPercent);
            } else {
                if (evolveCounter > 0){
                    skillPercent = this.FloorDecimal(m_skillPercent + ((evolveCounter - 1) * 0.1 * m_skillPercent));
                    //console.log(' not invole count Evolve: '+evolveCounter+' m_skillPercent '+m_skillPercent+" total "+skillPercent);
                }
            }

            return skillPercent;

        } else {
            if (possibleSkillLevel >= m_iCurrentLevel) {
                return m_skillPercent;
            } else {
                return 0;
            }
        }
    }

    FloorDecimal(value){
        let decim = Math.pow(10,5);
        return Math.trunc(value * decim) / decim;
    }

    getListGrouped(skillList) {
        let listGrouped = Tools.groupBy(skillList, 'supportData', 'm_iID');
        return listGrouped;
    }

    getSupportListOrderByPossibleUnlock(){
        let supports = [];
        let ori = this.gameDataService.supportGamePlay.supportData;
        ori.forEach(s => {
            supports.push(s);
        });

        supports = supports.sort((a, b) => (a.kpiGameLevelShouldUnlock > b.kpiGameLevelShouldUnlock) ? 1 : -1);
        return supports;
    }

    getPossibleSupportUnlocked(gameLevel, unlockFlyingSupportCounter) {
        return this.gameDataService.supportGamePlay.getPossibleSupportUnlocked(gameLevel, unlockFlyingSupportCounter);
    }

    /*disable feature saving coin for next unlock support
    getNextPossibleSupportUnlocked(gameLevel) {
        return this.gameDataService.supportGamePlay.getNextPossibleSupportUnlocked(gameLevel);
    }*/

    getGamePlayUnlockedTeam(teamSize) {
        let availableSupport = [];
        let supportPossibleUnlock = this.getSupportListOrderByPossibleUnlock();
        supportPossibleUnlock.forEach(s => {
            let support = s;
            let supportId = s.m_iID
            if (availableSupport.length < teamSize) {
                let supportData = {};
                supportData.m_iID = supportId;
                supportData.name = s.m_sName;
                supportData.icon = supportId + '.png';
                supportData.data = support;
                supportData.supportId = supportId;
                availableSupport.push(supportData);
            }
        })

        return availableSupport;
    }

    getGamePlayUnlockedAndLockTeam(supportIdsUnlock) {
        let availableSupport = [];
        let supportPossibleUnlock = this.getSupportListOrderByPossibleUnlock();
        supportPossibleUnlock.forEach(s => {
            let support = s;
            let supportId = s.m_iID;
            let supportData = {};
            supportData.m_iID = supportId;
            supportData.name = s.m_sName;
            supportData.icon = supportId + '.png';
            supportData.data = support;
            supportData.supportId = supportId;
            supportData.isUnlock = supportIdsUnlock.includes(supportId);//availableSupport.length < teamSize;
            supportData.levelUnlock = s.kpiGameLevelShouldUnlock;
            supportData.possibleLevel = 0;
            supportData.possibleSkillLevel = 0;
            supportData.m_SupportsAbilityList = s.m_SupportsAbilityList;
            availableSupport.push(supportData);
        });

        return availableSupport;
    }

    GetNumberOfSupportUnlockableAndGoldRemain(moneyAvailable, gameLevel, unlockFlyingSupportCounter, balSupportLevelBelowBoss) {
        let supportPossibleUnlockList = this.getPossibleSupportUnlocked(gameLevel, unlockFlyingSupportCounter);
        let moneyPossible = new SMPNum(0);
        let supportIdsUnlock = [];
        moneyPossible.setFromSMPNum(moneyAvailable);
        let moneyForBalanceLvUp = new SMPNum(0);
        let totalUnlockSupport = 0;
        let balanceLevel = gameLevel - (balSupportLevelBelowBoss + 2);//(BalanceConfigConstance.BALANCE_LEVEL_SUPPORT_AND_BOSS + 2);
        if(supportPossibleUnlockList.length > 0){
            let supportIndex = 0;
            let cost = this.CostUnlockSupport(supportPossibleUnlockList[supportIndex].m_iID);
            while (SMPNum.greaterThan(moneyPossible, cost)) {
                supportIdsUnlock.push(supportPossibleUnlockList[supportIndex].m_iID);
                totalUnlockSupport = totalUnlockSupport + 1;
                moneyPossible = SMPNum.minus(moneyPossible, cost);

                //include gold update level from level up to balance with boss
                if(supportPossibleUnlockList[supportIndex].kpiGameLevelShouldUnlock < balanceLevel){
                    let costLvUp = this.TotalOneSupportSpentOnLevelOnly(supportPossibleUnlockList[supportIndex].kpiGameLevelShouldUnlock, balanceLevel);
                    if(SMPNum.greaterThan(moneyPossible, costLvUp)){
                        moneyPossible = SMPNum.minus(moneyPossible, costLvUp);
                        moneyForBalanceLvUp = SMPNum.plus(moneyForBalanceLvUp, costLvUp);
                    } else {
                        //give money back and not unlock
                        totalUnlockSupport = totalUnlockSupport - 1;
                        moneyPossible = SMPNum.plus(moneyPossible, cost);
                        break;
                    }
                }

                supportIndex = supportIndex + 1;
                if(supportIndex >= supportPossibleUnlockList.length || totalUnlockSupport === 20){
                    break;
                } else {
                    cost = this.CostUnlockSupport(supportPossibleUnlockList[supportIndex].m_iID);
                }
            }
        }


        let goldRemain = moneyPossible;
        if(!moneyForBalanceLvUp.isZero){
            goldRemain = SMPNum.plus(goldRemain, moneyForBalanceLvUp);
        }

        return {
            totalUnlockSupport:totalUnlockSupport,
            goldRemain: goldRemain,
            supportIdsUnlock: supportIdsUnlock,
            //nextUnlockInfo: nextUnlockInfo
        };
    }

    GetNumberOfSupportUnlockable(moneyAvailable, gameLevel, unlockFlyingSupportCounter, balSupportLevelBelowBoss) {
        return this.GetNumberOfSupportUnlockableAndGoldRemain(moneyAvailable, gameLevel, unlockFlyingSupportCounter, balSupportLevelBelowBoss).totalUnlockSupport;
    }

    GetTotalUnlockSupportSpent(teamSize) {
        let spent = SMPNum.fromNum(0);
        for(let i=1; i<=teamSize;i++){
            spent = SMPNum.plus(spent, this.CostUnlockSupport(i));
        }
        return spent;
    }

    CostUnlockSupport(supportId) {
        return this.gameDataService.getCostUnlockSupport(supportId);
    }

    GetTotalCostUnlockSupportSkill(teamSize, fromSupportLevel, possibleSupportLevel){
        let spent = SMPNum.fromNum(0);
        let supportData = this.getSupportListOrderByPossibleUnlock();//this.gameDataService.supportGamePlay.supportData;
        let evolveCounter = 0;
        if(possibleSupportLevel > 1000){
            evolveCounter = Math.floor(possibleSupportLevel/1000);
        }
        for(let evolve = 0; evolve <= evolveCounter; evolve++){
            for(let i=0; i<teamSize;i++){
                let support = supportData[i];
                support.m_SupportsAbilityList.forEach(skill =>{
                    let lvSkill = skill.m_iCurrentLevel + (evolve * 1000);
                    if(fromSupportLevel < lvSkill && lvSkill <= possibleSupportLevel){
                        let costUnlock = this.gameDataService.getCostUnlockSkillSupport(support.m_iID, skill.m_iID, evolve);
                        spent = SMPNum.plus(spent, costUnlock);
                    }
                });
            }
        }
        return spent;
    }

    TotalSupportSpentReachToLevel(supportList){
        let spent = SMPNum.fromNum(0);
        // let msg = '';
        supportList.forEach(supports => {
            supports.forEach(support =>{
                if(support.isUnlock && support.possibleLevel > 1){
                    spent = SMPNum.plus(spent, this.CostUnlockSupport(support.m_iID));
                    let lvUpSpent = this.gameDataService.mathGamePlay.ComputeSumGeometricForSeries('CostSupport', support.possibleLevel);//this.gameDataService.getCostUpdateSupport(support.levelUnlock + 1, support.possibleLevel);
                    spent = SMPNum.plus(spent, lvUpSpent);
                    let maxFreeLevel = support.possibleLevel < support.levelUnlock ? support.possibleLevel : support.levelUnlock;
                    let costFirstLv = this.gameDataService.mathGamePlay.ComputeSumGeometricForSeries('CostSupport', maxFreeLevel);
                    spent = SMPNum.minus(spent, costFirstLv);
                    // msg += '\ns: '+support.m_iID+' unk: '+support.levelUnlock+" cost: "+this.CostUnlockSupport(support.m_iID).ToReadableAlphabetV2()+" LvUp: "+(support.possibleLevel-support.levelUnlock)+" cost: "+lvUpSpent.ToReadableAlphabetV2();
                    //msg += " Cost lv1-"+support.possibleLevel+": "+this.gameDataService.mathGamePlay.ComputeSumGeometricForSeries('CostSupport', support.possibleLevel).ToReadableAlphabetV2();
                }
            });
        });

        // console.log('msg: '+spent.ToReadableAlphabetV2()+msg);

        return spent;
    }

    TotalOneSupportSpentOnLevelOnly(fromLevel, levelTarget){
        let spent = SMPNum.fromNum(0);
        let lvUpSpent = this.gameDataService.getCostUpdateSupport(fromLevel, levelTarget);
        spent = SMPNum.plus(spent, lvUpSpent);
        return spent;
    }

    TotalSupportSpentReachToLevelById(supportId, levelUnlock, possibleLevel, skillLevel){
        let spent = SMPNum.fromNum(0);

        //unlock cost
        spent = SMPNum.plus(spent, this.CostUnlockSupport(supportId));

        //update cost
        let lvUpSpent = this.gameDataService.getCostUpdateSupport(levelUnlock, possibleLevel);
        spent = SMPNum.plus(spent, lvUpSpent);

        //unlock skill cost
        let evolveCounter = 0;
        if(skillLevel > 1000){
            evolveCounter = Math.floor(skillLevel/1000);
        }
        let support = this.gameDataService.supportGamePlay.supportData.find(s => s.m_iID === supportId);
        for(let evolve = 0; evolve <= evolveCounter; evolve++){
            support.m_SupportsAbilityList.forEach(skill =>{
                let lvSkill = skill.m_iCurrentLevel + (evolve * 1000);
                if(lvSkill <= skillLevel){
                    let costUnlock = this.gameDataService.getCostUnlockSkillSupport(support.m_iID, skill.m_iID, evolve);
                    spent = SMPNum.plus(spent, costUnlock);
                }
            });
        }

        return spent;
    }

    innerFunctionBuildPossibleLevelUpForSupport(moneyAvailable, supportList) {
        let moneyAvailableForOneSupport = new SMPNum();
        moneyAvailableForOneSupport.setFromSMPNum(moneyAvailable);

        let maxUnlockLevel = 1;
        supportList.forEach(supports => {
            supports.forEach(support => {
                if(support.isUnlock){
                    let unlockCost = this.CostUnlockSupport(support.m_iID);
                    if(SMPNum.greaterThan(moneyAvailableForOneSupport, unlockCost)){
                        moneyAvailableForOneSupport = SMPNum.minus(moneyAvailableForOneSupport, unlockCost);
                        if(maxUnlockLevel < support.levelUnlock){
                            maxUnlockLevel = support.levelUnlock;
                        }
                    }
                }
            });
        });

        return maxUnlockLevel + this.gameDataService.mathGamePlay.GetMaxLevelThatCanBuyWithExistingGold(moneyAvailableForOneSupport, maxUnlockLevel, 'CostSupport');
    }

    innerFunctionBuildPossibleLevelUpForOneSupport(moneyAvailable, currentLevel) {
        let moneyAvailableForOneSupport = new SMPNum(0);
        moneyAvailableForOneSupport.setFromSMPNum(moneyAvailable);

        return currentLevel + this.gameDataService.mathGamePlay.GetMaxLevelThatCanBuyWithExistingGold(moneyAvailableForOneSupport, currentLevel, 'CostSupport');
    }

    innerFunctionBuildPossibleUnlockSkillForSupport(moneyAvailable, supportList) {
        let moneyForSkill = new SMPNum();
        moneyForSkill.setFromSMPNum(moneyAvailable);
        let evolveCounter = 0;
        let possibleSpent = SMPNum.fromNum(0);
        //max support skill 10 per support
        let maxSkill = 10;

        for(let evolve = 0; evolve <= evolveCounter; evolve++){
            for(let skillIndex = 0; skillIndex < maxSkill; skillIndex++){
                supportList.forEach(supports => {
                    supports.forEach(support =>{
                        if(support.isUnlock){
                            let maxSupportLevel = support.possibleLevel;
                            if(maxSupportLevel > 1000){
                                evolveCounter = Math.floor(maxSupportLevel/1000);
                            }
                            if(skillIndex<support.m_SupportsAbilityList.length){
                                let skill = support.m_SupportsAbilityList[skillIndex];
                                let lvSkill = skill.m_iCurrentLevel + (evolve * 1000);
                                if(lvSkill <= maxSupportLevel){
                                    let costUnlock = this.gameDataService.getCostUnlockSkillSupport(support.m_iID, skill.m_iID, evolve);
                                    if(SMPNum.greaterThan(moneyForSkill, costUnlock)){
                                        moneyForSkill = SMPNum.minus(moneyForSkill, costUnlock);
                                        possibleSpent = SMPNum.plus(possibleSpent, costUnlock);
                                        if(support.possibleSkillLevel < lvSkill){
                                            support.possibleSkillLevel = lvSkill;
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
            }
        }

        let result = {
            totalSkillSpent: possibleSpent
        };

        return result;
    }

/*
    calcUtilsSupportLevelWithSkillUnlock(moneyAvailable, teamSize) {
        let spent = SMPNum.fromNum(0);

        if(teamSize === 0){
            return SMPNum.fromNum(0);
        }

        for(let i=1; i<=teamSize;i++){
            spent = SMPNum.plus(spent, this.CostUnlockSupport(i));
        }

        let supportData = this.gameDataService.supportGamePlay.supportData;
        let evolveCounter = 0;
        let lvResult = 1;
        let isDo = true;
        do{
            let nextLv = lvResult + 1;
            if(nextLv > 1000){
                evolveCounter = Math.floor(nextLv/1000);
            }
            for(let i=0; i<teamSize;i++){
                let support = supportData[i];

                //cost update lv
                spent = SMPNum.plus(spent, this.gameDataService.getSupportCost(nextLv));

                //cost unlock skill if need to unlock
                support.m_SupportsAbilityList.forEach(skill =>{
                    let lvSkill = skill.m_iCurrentLevel + (evolveCounter * 1000);
                    if(lvSkill === nextLv){
                        let costUnlock = this.gameDataService.getCostUnlockSkillSupport(support.m_iID, skill.m_iID, evolveCounter);
                        spent = SMPNum.plus(spent, costUnlock);
                    }
                });
            }
            if(SMPNum.greaterThan(spent, moneyAvailable)){
                isDo = false;
                break;
            } else {
                lvResult = nextLv;
            }
        } while (isDo);

        return SMPNum.fromNum(lvResult);
    }*/

    calcUtilsSupportLevelUp(previousLevel, moneyAvailable, seriesName) {
        let result = {
            levelPurchased: 0,
            moneyRemaining: moneyAvailable
        };

        let valueOfPreviousLevel = new SMPNum(1);
        valueOfPreviousLevel.setPower(previousLevel._power);
        previousLevel = previousLevel.ToIntValue();

        if (previousLevel !== valueOfPreviousLevel.ToIntValue()) {
            throw 'CALC-UTILS-LVL2: Previous level mismatching , value of pervious level';
        }

        let interval = this.gameDataService.mathGamePlay.mathCore.getSequenceIntervalInfoFor(previousLevel, seriesName);
        let check = true;
        do {
            let lvPossible = interval.max + 1;
            let sum = this.gameDataService.mathGamePlay.ComputeRemainingSumForSeries(seriesName, lvPossible);
            let hasEnoughToGoToNextSeries = SMPNum.greaterThan(moneyAvailable, sum);
            if (hasEnoughToGoToNextSeries) {
                moneyAvailable = SMPNum.minus(moneyAvailable, sum);
                previousLevel = lvPossible;
                interval = this.gameDataService.mathGamePlay.mathCore.getSequenceIntervalInfoFor(previousLevel, seriesName);
            } else {
                check = false;
            }

        } while (interval.max !== 500000 && check);

         let costForNextlevel = this.gameDataService[seriesName](previousLevel+1);
        if (!SMPNum.greaterThan(moneyAvailable, costForNextlevel)) {
            try {
                result.moneyRemaining = moneyAvailable;
                result.levelPurchased = previousLevel - valueOfPreviousLevel.ToIntValue();
                return new SMPNum(result.levelPurchased);
            } catch (e) {
                throw  'CALC-UTILS-LVL2: pb in CalcLevelup previousLevel ' + previousLevel + ' valueOf ' + valueOfPreviousLevel.ToIntValue()
            }
        }
        let startLevel = previousLevel;
        let u1 = this.gameDataService[seriesName](startLevel);
        let rRatio = this.gameDataService.mathGamePlay.mathCore.GetSeriesCommonRatioByLevel(previousLevel, seriesName);

        // A = (ratio - 1) * moneyAvailable;
        // B = CostStartLv * ratio^StartLv;
        // formula = A/B + 1;
        // targetLevel = log(formula, ratio);

        let r = SMPNum.fromNum(rRatio);
        let A = SMPNum.multSmpNum(
            SMPNum.minus(r, new SMPNum(1)),
            moneyAvailable);

        let B = SMPNum.multSmpNum(u1
            , SMPNum.pow(r, startLevel)
        );

        let tempDivResult = SMPNum.divSmpNum(A, B);

        let formula = SMPNum.plus(
            tempDivResult,
            new SMPNum(1)
        );
        let targetLevel = null;
        try {
            targetLevel = SMPNum.logBaseSmpNum(formula, rRatio)
        } catch (error) {
            //console.log('Crash for previous level: ')
        }

        if (!targetLevel || targetLevel.isZero) {
            result.moneyRemaining = moneyAvailable;
            if (previousLevel - valueOfPreviousLevel.ToIntValue() === 0) {
                return new SMPNum(0);
            } else {
                result.levelPurchased = SMPNum.minus(new SMPNum(previousLevel), valueOfPreviousLevel);
                return new SMPNum(previousLevel);
            }
        } else {
            return targetLevel;
        }
    }
}

export default SupportKpiUtils;